.step {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  flex-direction: column;
  -ms-flex-direction: column;
  padding: 18px 24px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.icon {
  margin-right: 10px;
  margin-bottom: 15px;
}

.icon svg {
  width: 100px;
}

.title {
  font-size: 1.6rem;
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .step {
    width: 100%;
  }
}

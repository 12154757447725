.roadmap {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}

.roadmap svg {
  width: 50px;
}

.container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}

@media screen and (max-width: 1000px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: 1fr;
  }
}

.stats__card {
  padding: 15px 24px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 200px;
}

.content h2 {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
}

.content h3 {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 500;
}

.content h3 span {
  color: var(--purple-color);
  font-weight: 600;
}

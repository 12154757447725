.PlaceContainer {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.DropDownHeader {
  font-size: 20px;
  padding: 12px;
  cursor: pointer;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
}

.Heading {
  margin: 0px;
  margin-right: 4px;
}

.PlaceDropDownList {
  width: 100%;
  position: absolute;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.PlaceListItem {
  font-size: 1.5rem;
  margin: 2px 0px;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  cursor: pointer;
  color: #000;
  padding: 5px 15px;
}

.PlaceListItem span {
  font-weight: 500;
}

.PlaceListItem:hover {
  transition: 0.2s ease-in;
}

.PlaceListItem[active] {
  color: #888;
}

.loader h4 {
  margin-top: 10px;
  font-size: 1.5rem;
}

.input {
  width: 100%;
}

.label__wrapper {
  margin-bottom: 6px;
}

.label__wrapper .label {
  font-size: 1.6rem;
  font-weight: 500;
}

.input input {
  padding: 15px 16px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  font-size: 1.6rem;
  outline: none;
  color: var(--dark-blue-color);
  font-weight: 500;
}

.input input.invalid {
  border-color: var(--red-color);
}

.input input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.6rem;
}

.input .dark::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input.left input {
  padding-left: 5rem;
}

.input input.dark {
  padding: 12px 18px !important;
}

.field {
  position: relative;
  display: flex;
  display: -ms-flexbox;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.field .icon {
  position: absolute;
}

.icon__left {
  left: 16px;
}

.icon__right {
  right: 16px;
  cursor: pointer;
  margin-top: 2px;
}

@media screen and (max-width: 625px) {
  .input input.dark {
    font-size: 1.2rem;
    padding-block: 12px;
    font-weight: 400;
  }
}

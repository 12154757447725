.hero {
  padding-top: 160px;
  position: relative;
}

.hero h1 {
  font-size: 7rem;
  font-weight: bolder;
  line-height: 130%;
  margin-top: 30px;
  background-clip: text;
  background: linear-gradient(to right, var(--dark-blue-color) 0, var(--purple-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  font-size: 2rem;
  max-width: 700px;
}

.hero > div {
  flex: 1;
  -ms-flex: 1;
}

.hero > div:nth-child(1) {
  flex: 2.5;
  -ms-flex: 2.5;
}

.button__group {
  margin-top: 20px;
}

.hero .white {
  fill: var(--white-color);
}

.hero button .white,
.hero button .purple {
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.hero button:hover .white,
.hero button:hover .purple {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
}

.hero .purple {
  fill: var(--purple-color);
}

.illustration img {
  width: 500px;
  height: auto;
  margin-top: -140px;
}

@media screen and (max-width: 1234px) {
  .illustration img {
    width: 400px;
    margin-top: -100px;
  }
}

@media screen and (max-width: 1030px) {
  .hero h1 {
    font-size: 6rem;
  }

  .hero p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 990px) {
  .illustration,
  .community {
    display: none;
  }

  .hero h1 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 748px) {
  .hero {
    padding-top: 24px;
  }
}

@media screen and (max-width: 580px) {
  .button__group {
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .button__group > div {
    width: 100%;
  }

  .hero h1 {
    font-size: 4rem;
  }

  .hero p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 430px) {
  .hero h1 {
    font-size: 3rem;
  }
}

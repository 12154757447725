.loader {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.loader div {
  position: absolute;
  top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 0.6s infinite;
  -webkit-animation: loader1 0.6s infinite;
}

.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 0.6s infinite;
  -webkit-animation: loader2 0.6s infinite;
}

.loader div:nth-child(3) {
  left: 32px;
  animation: loader2 0.6s infinite;
  -webkit-animation: loader2 0.6s infinite;
}

.loader div:nth-child(4) {
  left: 56px;
  animation: loader3 0.6s infinite;
  -webkit-animation: loader3 0.6s infinite;
}

@keyframes loader1 {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}

@keyframes loader3 {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
  }
}

@keyframes loader2 {
  0% {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
    -webkit-transform: translate(24px, 0);
    -moz-transform: translate(24px, 0);
    -ms-transform: translate(24px, 0);
    -o-transform: translate(24px, 0);
  }
}

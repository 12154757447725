.stats {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.badge__stat {
  margin-top: 24px;
}

.badge__stat h2 {
  font-size: 1.8rem;
  max-width: 700px;
}

@media screen and (max-width: 950px) {
  .stats {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 650px) {
  .stats {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .badge__stat {
    flex-direction: column;
    -ms-flex-direction: column;
  }
}

.hero {
  padding-top: 160px;
  position: relative;
}

.share {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.share .content {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--purple-color);
  background: var(--white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.hero h1 {
  font-size: 6rem;
  font-weight: bolder;
  line-height: 130%;
}

.hero p {
  font-size: 2rem;
  max-width: 900px;
}

.hero > div {
  flex: 1;
  -ms-flex: 1;
}

.hero > div:nth-child(1) {
  flex: 2.5;
  -ms-flex: 2.5;
}

.more {
  margin-top: 20px;
  padding: 16px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--purple-color);
  width: fit-content;
}

.more span {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--white-color);
}

.speakers {
  margin-top: 32px;
}

.display .image {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}

.display .image img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media screen and (max-width: 1030px) {
  .hero h1 {
    font-size: 5rem;
  }

  .hero p {
    font-size: 2rem;
  }

  .display {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 990px) {
  .hero h1 {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 748px) {
  .hero {
    padding-top: 24px;
  }
}

@media screen and (max-width: 730px) {
  .display {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 580px) {
  .hero h1 {
    font-size: 4rem;
  }

  .hero p {
    font-size: 1.8rem;
  }
}

.notification {
  position: fixed;
  bottom: 24px;
  right: -100vw;
  z-index: 99999;
  background: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 14px 16px;
  width: 100%;
  max-width: 370px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.notification.active {
  right: 24px;
}

.icon {
  margin-top: 1px;
}

.notification h2 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 5px;
}

.notification h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

.features {
  padding-top: 0;
}

.features__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

@media screen and (max-width: 1234px) {
  .features__list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 740px) {
  .features__list {
    grid-template-columns: 1fr;
  }
}

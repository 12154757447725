.footer {
  padding-top: 32px;
  padding-bottom: 32px;
}

.footer .logo p {
  font-size: 1.8rem;
  margin-top: 20px;
  max-width: 900px;
}

.footer .logo h2 {
  font-size: 5rem;
  font-weight: 600;
  letter-spacing: -2px;
  font-family: 'Poppins', sans-serif;

  background: #0b3558;
  background-clip: text;
  background: linear-gradient(to right, #0b3558 0%, #980ecb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer_component {
  min-height: 20vh;
  padding: 4rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  gap: 48px;
}

.sub h3 {
  font-size: 1.9rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer__list {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  gap: 16px;
  list-style-type: none;
}

.footer__list a {
  font-size: 1.6rem;
  text-decoration: none;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.footer__list a:hover {
  color: var(--purple-color);
}

.mark {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.mark h4,
.mark a {
  font-size: 1.7rem;
  text-decoration: none;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  .mark {
    gap: 16px;
  }
}

@media screen and (max-width: 748px) {
  .logo svg {
    width: 120px;
  }
}

@media screen and (max-width: 548px) {
  .logo svg {
    width: 80px;
  }
}

.track__details {
  padding-top: 0;
}

.tabs__control {
  gap: 6px;
  overflow-x: auto;
}

.tabs__control::-webkit-scrollbar {
  display: none;
}

.tabs__control button {
  padding: 16px 24px;
  font-size: 1.8rem;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  border-bottom: 3px solid transparent;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  font-weight: 500;
  background: rgba(154, 14, 205, 0.1);
  white-space: nowrap;
}

.tabs__control button.active {
  border-bottom: 3px solid var(--purple-color);
  color: var(--purple-color);
  background: rgba(154, 14, 205, 0.1);
}

@media screen and (max-width: 760px) {
  .tabs__control button {
    font-size: 1.6rem;
  }
}

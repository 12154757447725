.not__found h2 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-top: -120px;
}

.not__found a {
  font-size: 1.8rem;
}

.not__found svg {
  max-width: 300px;
  margin-top: -130px;
}

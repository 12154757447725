.profile__module > h2 {
  font-size: 2.2rem;
  font-weight: 600;
  background: var(--purple-color);
  width: fit-content;
  color: #fff;
  padding: 0 10px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.profile__module > p {
  font-size: 1.7rem;
  width: 100%;
  max-width: 800px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.check > div {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.profile__module .label__wrapper label,
.endore h4,
.check h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

.profile__module textarea {
  padding: 15px 18px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  font-size: 1.6rem;
  outline: none;
  color: var(--dark-blue-color);
  font-weight: 500;
  resize: vertical;
  line-height: 160%;
  margin-top: -10px;
}

.profile__module textarea::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.6rem;
}

.endore {
  width: fit-content;
}

.endore > h4 {
  margin-bottom: 6px;
  white-space: nowrap;
}

.skills__list {
  gap: 8px;
  margin-top: -10px;
}

.skill {
  padding: 10px 20px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background: #000;
}

.skill h4 {
  font-size: 1.6rem;
  color: #fff;
}

.notice {
  font-size: 1.8rem;
}

.quote {
  padding-top: 0;
  background: url('../../../../../../repository/assets/bg.svg') no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 80vh;
}

@media screen and (max-width: 1234px) {
  .quote {
    min-height: 60vh;
  }
}

@media screen and (max-width: 1234px) {
  .quote {
    min-height: fit-content;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800&display=swap');

*,
*::before,
*::after {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  color: var(--dark-blue-color);
}

:root {
  --dark-blue-color: #0b0530;
  --purple-color: #980ecb;

  --red-color: #d30b0b;
  --green-color: #0a9f90;
  --yellow-color: #f0bb0a;

  --white-color: #fff;
  --dark-color: #020b13;

  --padding-desktop: 64px;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  width: 100%;
  overflow-x: hidden;
}

img,
figure,
video {
  width: 100%;
}

input {
  background-color: var(--white-color);
  outline: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  overflow: hidden;
}

input.dark:-webkit-autofill,
input.dark:-webkit-autofill:hover,
input.dark:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: 0 0 0px 1000px #1b1a1a inset;
  -webkit-box-shadow: 0 0 0px 1000px #1b1a1a inset;
  overflow: hidden;
  outline: none;
}

input:focus {
  outline: unset;
}

input[type='checkbox'] {
  opacity: 0;
}

.popup {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  max-width: 700px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 24px;
  position: relative;
}

.popup h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 16px;
  width: 100%;
}

.popup h3 {
  font-size: 1.8rem;
  margin-bottom: 24px;
}

.langs {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 12px;
}

.langs button {
  padding: 10px 16px;
  background: transparent;
  color: var(--dark-blue-color);
  border: 2px solid var(--dark-blue-color);
  font-weight: 600;
  font-size: 1.6rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.langs button:hover,
.langs button.active {
  color: #fff;
  background: var(--dark-blue-color);
}

@media screen and (max-width: 1440px) {
  :root {
    --padding-desktop: 48px 48px;
  }
}

@media screen and (max-width: 1133px) {
  :root {
    --padding-desktop: 48px 32px;
  }
}

@media screen and (max-width: 748px) {
  :root {
    --padding-desktop: 48px 24px;
  }

  .popup {
    padding: 16px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --padding-desktop: 48px 16px;
  }
}

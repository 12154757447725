.sidebar {
  isolation: isolate;
  user-select: none;
  -webkit-user-select: none;
}

.list {
  list-style-type: none;
}

.logo {
  padding: 16px;
  padding-block: 0;
}

.list a {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  padding: 16px;
  border-left: 5px solid transparent;
  background: var(--white-color);
  color: var(--dark-color);
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.list a.active {
  border-color: var(--purple-color);
  color: var(--purple-color);
  background: rgba(0, 21, 251, 0.1);
}

.overlayed {
  position: relative;
}

.overlayed:hover {
  cursor: not-allowed;
}

.overlayed::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
}

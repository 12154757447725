.headline {
  font-size: 3rem;
  font-weight: 700;
  font-family: 'Barlow', sans-serif;
  max-width: 600px;
  background: linear-gradient(to right, var(--dark-blue-color) 0, var(--purple-color) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headline.dark {
  background: linear-gradient(to right, var(--white-color) 0, var(--purple-color) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headline.large {
  font-size: 5rem;
  max-width: 100%;
}

.wrapper {
  gap: 6px;
}

.wrapper.center {
  align-items: center;
  justify-content: center;
}

.wrapper.center .headline {
  text-align: center;
}

@media screen and (max-width: 1234px) {
  .headline.large {
    font-size: 4rem;
  }
}

@media screen and (max-width: 990px) {
  .headline.large {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 540px) {
  .headline.large {
    font-size: 3rem;
  }
}

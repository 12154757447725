.members {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  padding-top: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.overlay.active {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

@media screen and (max-width: 1030px) {
  .members {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 740px) {
  .members {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.features {
  padding-top: 0;
  margin-top: -130px;
}

.features__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  row-gap: 32px;
}

@media screen and (max-width: 1234px) {
  .features {
    margin-top: -100px;
  }

  .features__list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 990px) {
  .features {
    margin-top: 0;
  }
}

@media screen and (max-width: 580px) {
  .features__list {
    grid-template-columns: 1fr;
  }
}

.searchable {
  position: relative;
}

.drop__down {
  width: 100%;
  position: absolute;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.drop__down > div {
  padding: 8px 16px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.drop__down > div:hover {
  background: rgba(0, 0, 0, 0.03);
}

.drop__down span {
  font-weight: 500;
  font-size: 1.6rem;
}

.PlaceListItem:hover {
  transition: 0.2s ease-in;
}

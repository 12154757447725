.image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  border: 5px solid rgba(0, 0, 0, 0.1);
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.text h2 {
  font-size: 3rem;
  font-weight: 600;
}

.text h3 {
  font-size: 2rem;
}

.parent {
  width: 100%;
  max-width: 700px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 64px 32px;
  border-top: 5px solid var(--purple-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
}

.parent p {
  font-size: 1.8rem;
  text-align: center;
}

@media screen and (max-width: 840px) {
  .parent {
    padding: 48px 16px;
  }
}

@media screen and (max-width: 638px) {
  .profile {
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: flex-start;
  }

  .parent p {
    text-align: start;
    font-size: 1.6rem;
  }

  .text h2 {
    font-size: 2.5rem;
  }

  .parent {
    padding: 24px 16px;
  }
}

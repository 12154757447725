.dashboard__page {
  position: relative;
}

.content__data {
  isolation: isolate;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 96px;
  width: 280px;
  height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--white-color);
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.sidebar.close {
  left: -150vw;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 96px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 99999;
  padding: 0 16px;
  background: var(--white-color);
}

.toggler {
  cursor: pointer;
}

.toggler h4 {
  font-size: 2.5rem;
  color: var(--dark-color);
}

.toggler h4 span {
  font-weight: 500;
  color: var(--dark-color);
}

.content {
  position: absolute;
  left: 280px;
  width: calc(100% - 280px);
  top: 96px;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  background: #fff;
  padding: 32px;
}

.content.full {
  width: 100%;
  left: 0;
}

.profile {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  margin-left: 16px;
  cursor: pointer;
  border: 3px solid var(--purple-color);
}

.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.more {
  position: relative;
}

.logout {
  position: absolute;
  bottom: -80px;
  right: 0;
  background: #fff;
  padding: 8px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  z-index: 99999;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.logout button {
  padding: 10px 16px;
  background: transparent;
  border: none;
  font-size: 1.6rem;
  outline: none;
  cursor: pointer;
  font-weight: 600;
}

@media screen and (max-width: 830px) {
  .content {
    left: 0;
    width: 100%;
  }

  .sidebar {
    z-index: 1;
  }
}

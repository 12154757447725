.button {
  font-size: 1.8rem;
  font-weight: 500;
  padding: 12px 24px;
  background: var(--purple-color);
  color: #fff;
  border: 2px solid transparent;
  border-radius: 4px;
  min-height: 50px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  cursor: pointer;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  outline: none;
  width: 100%;
  white-space: nowrap;
}

.button span {
  color: inherit;
}

.button:hover,
.button.secondary {
  background: var(--purple-color);
}

.button.tertiary {
  background: var(--green-color);
}

.button.primary.outlined {
  border: 2px solid var(--purple-color);
  color: var(--purple-color);
  background: transparent;
}

.button.primary.outlined:hover {
  background: rgba(154, 14, 205, 0.2);
}

.auth__ref h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 40px;
}

.auth__ref h3 {
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: 500;
}

.auth__ref a {
  color: #fff;
  text-decoration: none;
  padding: 14px 32px;
  font-size: 1.6rem;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background: var(--purple-color);
  font-weight: 700;
}

@media screen and (max-width: 800px) {
  .auth__ref h2 {
    font-size: 2.5rem;
  }

  .auth__ref h3 {
    font-size: 1.8rem;
  }
}

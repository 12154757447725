.badges__status {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  padding: 24px;
  border-top: 5px solid var(--purple-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.main h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.main h3 {
  font-size: 3rem;
  font-weight: 600;
}

.ref {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.badge {
  width: 170px;
  height: 170px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  margin-top: 20px;
}

.badge img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  -o-object-fit: cover;
  -o-object-position: center;
}

.profile__form {
  flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
}

.profile__form > div {
  flex: 1;
  -ms-flex: 1;
}

.controllers {
  margin-top: 24px;
}

.svg img {
  height: fit-content;
}

.loader {
  position: relative;
}

.loader::before {
  content: '';
  height: 100%;
  position: absolute;
  left: 0%;
  top: 0;
  box-shadow: 0 0 110px 40px #fff;
  z-index: 10;
  animation: move 2s linear infinite;
  -webkit-animation: move 2s linear infinite;
}

@keyframes move {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

.loader > div {
  background: rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.loader .one {
  width: 100%;
  max-width: 300px;
}

.loader .two {
  margin-top: -16px;
  height: 60px;
}

.loader .three {
  height: 180px;
}

@media screen and (max-width: 750px) {
  .svg {
    display: none;
  }
}

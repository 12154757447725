.card {
  background: #fff;
  border-radius: 40px;
  padding: 32px 16px;
  width: 100% !important;
  padding-bottom: 48px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.card.overlayed {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  align-items: flex-start;
  padding: 32px;
  gap: 16px;
}

.admin {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 16px;
  background: var(--dark-blue-color);
  border-bottom-left-radius: 10px;
}

.admin span {
  color: #fff;
  font-size: 1.6rem;
}

.card:hover {
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.card.overlayed:hover {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.badge {
  position: absolute;
  display: none;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.image {
  border: 5px solid rgba(149, 157, 165, 0.3);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: top;
  -o-object-position: top;
}

.info h2 {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

.info h3 {
  font-size: 1.6rem;
  text-align: center;
}

.card.overlayed .info h2,
.card.overlayed .info h3 {
  text-align: left;
}

.social__handles {
  width: 100%;
  max-width: 200px;
  margin-top: 24px;
  isolation: isolate;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.card.overlayed .social__handles {
  justify-content: flex-start;
}

.social__handles a {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.more p {
  font-size: 1.5rem;
  line-height: 150%;
}

.more p a,
.more p b {
  font-weight: 700;
  text-decoration: none;
  color: var(--red-color);
}

@media screen and (max-width: 840px) {
  .card.overlayed {
    padding: 32px 16px;
  }

  .more p {
    font-size: 1.4rem;
  }
}

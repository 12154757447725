.auth {
  min-height: 100vh;
  padding-block: 20px;
}

.logo {
  margin-bottom: 30px;
}

.third__party {
  margin-bottom: 20px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 13px 20px;
  font-size: 1.6rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  position: relative;
}

.google {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0.01;
}

.google div,
.google iframe {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.lines {
  margin-bottom: 20px;
}

.lines > div {
  height: 2px;
  width: 100%;
  border-radius: 50px;
  max-width: 100px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background: rgba(0, 0, 0, 0.1);
}

.lines h4 {
  font-weight: 500;
  font-size: 1.8rem;
}

.third__party img {
  width: 25px;
  height: 25px;
}

.auth__container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 400px;
  width: 100%;
  max-width: 1100px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}

.auth__container > div {
  flex: 1;
  -ms-flex: 1;
  min-height: 100%;
}

.auth__container .form {
  flex: 1.3;
  -ms-flex: 1.3;
}

.auth__container .banner {
  background: var(--purple-color);
  padding: 20px 24px;
}

.banner h2 {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
}

.banner a {
  font-size: 2rem;
  color: #fff;
  text-decoration: none;
}

.form {
  padding: 48px 64px;
  min-height: 100%;
}

.form__wrapper {
  width: 100%;
}

.link {
  width: 100%;
  margin-top: -12px;
}

.link a {
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
}

.form h2 {
  font-weight: 600;
  font-size: 3.5rem;
  margin-bottom: 24px;
}

.validation__errors {
  padding-left: 20px;
}

.validation__errors li {
  font-size: 1.6rem;
  margin-bottom: 8px;
  color: var(--red-color);
  font-weight: 600;
}

.ref__link {
  display: none;
}

.ref__link h4 {
  font-size: 1.5rem;
  font-size: 600;
}

.ref__link h4 a {
  font-weight: 700;
}

@media screen and (max-width: 1440px) {
  .form {
    padding: 48px 48px;
  }
}

@media screen and (max-width: 1133px) {
  .form {
    padding: 48px 32px;
  }
}

@media screen and (max-width: 748px) {
  .banner {
    display: none !important;
  }

  .ref__link {
    display: block;
  }

  .form {
    padding: 48px 0;
  }

  .auth__container {
    box-shadow: none;
  }

  .form h2 {
    font-size: 3rem;
  }

  .auth {
    min-height: fit-content;
  }

  .logo {
    width: 100%;
    margin-bottom: 12px;
  }

  .logo svg {
    width: 130px;
  }
}

.timer__date {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 24px;
  flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  background: var(--purple-color);
}

.timer__date.reduced {
  padding: 16px;
  align-items: flex-start;
}

.head h2 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.head span {
  font-size: 1.8rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.timer__date.reduced .head h2,
.timer__date.reduced .head span {
  font-size: 1.6rem;
}

.time h2 {
  font-size: 4rem;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

.timer__date.reduced .time h2 {
  font-size: 3rem;
  margin-bottom: 4px;
}

.time h2 span {
  font-size: 2rem;
  margin-left: 5px;
  font-weight: 500;
  color: #fff;
}

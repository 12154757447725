.profile__management {
  margin-top: 32px;
  align-items: flex-end;
}

.image__upload {
  position: relative;
  width: fit-content;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 3px solid var(--purple-color);
}

.image__upload .upload__icon {
  position: absolute;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  width: 100%;
  height: 50%;
  left: 0;
  top: 50%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.input {
  position: absolute;
  width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.input input {
  position: absolute;
  width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}

.image__upload .upload__icon:hover {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  top: 0;
}

.image__upload .image {
  width: 200px;
  height: 200px;
  overflow: -moz-hidden-unscrollable;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.displays {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
  padding: 24px 16px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.displays.darken {
  position: relative;
}

.displays.darken::before {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  content: 'Coming Soon... 🤩';
  position: absolute;
  color: var(--white-color);
  font-size: 2.5rem;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
}

.reverse {
  flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  align-items: flex-start;
}

.form {
  margin-top: 24px;
}

.form h2 {
  font-size: 2.2rem;
  font-weight: 600;
}

.form h3 {
  font-size: 1.8rem;
}

.form h3.large {
  font-size: 1.8rem;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background: var(--dark-color);
  color: var(--white-color);
  width: fit-content;
}

.points {
  margin-top: 10px;
  display: flex;
  display: -ms-flexbox;
  gap: 20px;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.point {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 1024px) {
  .reverse {
    flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
  }
}

@media screen and (max-width: 850px) {
  .image__upload,
  .image__upload .image {
    width: 130px;
    height: 130px;
  }
}

.navbar {
  width: 100%;
  position: fixed;
  padding-top: 20px;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  transition: 0.2s ease-in-out;
  z-index: 9999;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.navbar.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.burger {
  display: none;
}

.navbar ul {
  list-style-type: none;
}

.navbar a {
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 500;
  position: relative;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 6px;
}

.navbar ul a:hover,
.navbar ul a.active {
  color: var(--purple-color);
}

@media screen and (max-width: 1200px) {
  .burger {
    display: flex;
    cursor: pointer;
  }

  .menu {
    position: fixed;
    left: -150vw;
    transition: 0.2s ease-in-out;
    top: 104px;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-desktop);
    padding-top: 0;
    background: #fff;
    width: 100%;
    height: calc(100vh - 104px);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
  }

  .logo svg {
    width: 140px;
  }

  .menu.show {
    left: 0;
  }
}

@media screen and (max-width: 748px) {
  .logo svg {
    width: 120px;
  }

  .navbar {
    position: relative;
  }
}

@media screen and (max-width: 548px) {
  .logo svg {
    width: 80px;
  }

  .hide {
    display: none;
  }
}

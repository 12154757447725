.mentor {
  padding-top: 0;
}

.mentor .users {
  width: 400px;
  height: auto;
}

.texts p {
  font-size: 1.8rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.overlay.active {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.overlay span {
  font-size: 2rem;
  font-weight: 600;
}

.overlay p {
  margin-bottom: 10px;
}

.overlay .logo {
  margin-bottom: 20px;
}

.overlay a {
  font-size: 1.5rem;
  text-decoration: none;
}

.box {
  padding: 24px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--white-color);
  width: 100%;
  max-width: 600px;
}

.wrapper__suggests {
  margin-bottom: 20px;
}

.lists {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.lists .item {
  padding: 8px 14px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  background: var(--green-color);
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 14px;
}

.overlay .lists .item span {
  font-size: 1.5rem !important;
  font-weight: 500;
  color: var(--white-color);
}

@media screen and (max-width: 1234px) {
  .mentor .users {
    width: 300px;
  }

  .content {
    gap: 32px;
  }
}

@media screen and (max-width: 990px) {
  .content {
    flex-direction: column;
    -ms-flex-direction: column;
  }

  .content .svg__illus {
    display: flex;
    display: -ms-flexbox;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
}

@media screen and (max-width: 748px) {
  .box {
    padding: 16px;
  }
}

.head h2 {
  font-size: 3rem;
  margin-top: 10px;
  font-weight: 600;
}

.head p {
  font-size: 1.7rem;
  margin: 8px 0;
  width: 100%;
  max-width: 800px;
  line-height: 1.5;
}

.image {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 80px;
  height: 80px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  cursor: pointer;
  border: 2px solid var(--ROYALTY);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

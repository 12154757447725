.list {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  margin-top: 32px;
}

.list div {
  width: 100%;
}

.holder {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.list a {
  text-decoration: none;
  padding: 32px 16px;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.list a:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.list h3 {
  margin-top: 12px;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

.list p {
  font-size: 1.6rem;
  text-align: center;
  font-weight: 500;
}

@media screen and (max-width: 1234px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 890px) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 540px) {
  .list {
    grid-template-columns: 1fr;
  }
}

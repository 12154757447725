.hero {
  padding-top: 160px;
  position: relative;
}

.hero h1 {
  font-size: 6rem;
  font-weight: bolder;
  line-height: 130%;
}

.hero p {
  font-size: 1.8rem;
}

.button__group {
  margin-top: 20px;
}

.hero .white {
  fill: var(--white-color);
}

.hero button .white,
.hero button .purple {
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.hero button:hover .white,
.hero button:hover .purple {
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
}

.hero .purple {
  fill: var(--purple-color);
}

.illustration {
  display: flex;
  display: -ms-flexbox;
  align-items: flex-end;
  justify-content: flex-end;
}

/* .illustration .image {
  margin-top: -500px;
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.overlay.active {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.overlay span {
  font-size: 2rem;
  font-weight: 600;
}

.overlay p {
  margin-bottom: 10px;
}

.overlay .logo {
  margin-bottom: 20px;
}

.overlay a {
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: 600;
}

.box {
  padding: 24px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: var(--white-color);
  width: 100%;
  max-width: 600px;
}

@media screen and (max-width: 1030px) {
  .hero h1 {
    font-size: 5rem;
  }

  .hero p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 990px) {
  .hero h1 {
    font-size: 4.5rem;
  }

  .illustration {
    display: none;
  }
}

@media screen and (max-width: 748px) {
  .hero {
    padding-top: 24px;
  }

  .box {
    padding: 16px;
  }
}

@media screen and (max-width: 580px) {
  .hero h1 {
    font-size: 4rem;
  }

  .hero p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 430px) {
  .hero h1 {
    font-size: 3rem;
  }
}

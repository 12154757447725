.feature__card h3 {
  font-size: 2rem;
  font-weight: 600;
}

.feature__card.small {
  gap: 8px;
  padding: 24px 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.feature__card p {
  font-size: 1.6rem;
}

.box {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

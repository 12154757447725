.select__wrapper .select {
  padding: 14px 18px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  font-size: 1.6rem;
  outline: none;
  color: var(--dark-blue-color);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.select__wrapper {
  position: relative;
}

.select__wrapper h4 {
  white-space: nowrap;
  font-weight: 500;
}

.options {
  background: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 99;
  top: 0;
  padding: 8px 0;
  height: 132px;
  width: fit-content;
  overflow-y: auto;
}

.options h4 {
  padding: 8px 18px;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
}

.mentor {
  background: var(--dark-color);
  background: -webkit-linear-gradient(90deg, var(--dark-color) 0%, var(--dark-blue-color) 100%);
  background: linear-gradient(90deg, var(--dark-color) 0%, var(--dark-blue-color) 100%);
  padding-block: 128px;
  position: relative;
}

.drop {
  position: absolute;
  height: 200px;
  width: 200px;
  background: var(--white-color);
  border-radius: 51% 49% 48% 52% / 62% 44% 56% 38%;
  opacity: 0.8;
  border: 2px solid var(--white-color);
  right: 32px;
  bottom: 32px;
}

.drop::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--purple-color);
  border-radius: 51% 49% 48% 52% / 62% 44% 56% 38%;
  box-shadow:
    -5px 30px 16px var(--dark-color),
    10px 40px 32px var(--dark-color),
    -6px 6px 10px var(--dark-color),
    inset 2px 6px 10px var(--dark-color),
    inset 20px -20px 22px var(--white-color),
    inset 40px -40px 44px var(--purple-color);
}

.drop::after {
  content: '';
  position: absolute;
  height: 40px;
  width: 40px;
  background: #e6fdfb;
  border-radius: 44% 56% 46% 54% / 36% 50% 50% 64%;
  left: 130px;
  top: 40px;
  box-shadow: 16px 40px 0 -10px white;
  opacity: 0.8;
}

.texts p {
  font-size: 1.8rem;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  color: var(--white-color);
}

@media screen and (max-width: 1234px) {
  .mentor .users {
    width: 300px;
  }

  .content {
    gap: 32px;
  }

  .mentor {
    padding-block: 64px;
  }

  .drop {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .mentor {
    padding-block: 48px;
  }
}
